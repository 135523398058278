import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Courses from "./pages/Courses/Courses";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Grades from "./pages/Grades/Grades";
import Boards from "./pages/Boards/Boards";
import Teachers from "./pages/Teachers/Teachers";
import NavBar from "./components/Header/Navbar";
import Footer from "./components/Footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";

function App() {
  return (
    <div style={{ backgroundColor: "#050a23" }}>
      <NavBar />
      <div className="2xl:container 2xl:mx-auto bg-white">
        <FloatingWhatsApp
          avatar={require("./utils/images/teachers/teacher-1.jpg")}
          phoneNumber={"+923149312014"}
          accountName={"Eahanatuitions"}
        />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/grades" element={<Grades />} />
          <Route path="/boards" element={<Boards />} />
          <Route path="/teachers" element={<Teachers />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
