import React from 'react';
import { BoardsTitles } from "../../utils/strings/values";
import "./Boards.css";
import styled from 'styled-components';
import { Link } from 'react-router-dom';

function Boards() {
  return (
    <div className='boards-page'>
      <header className='header'>
        <div className='header-content container vh-100 d-flex flex-column align-items-center justify-content-center text-light'>
          <h1 className='header-title text-center fw-semibold text-main'>Boards</h1>
          <p className='header-description'>
            Explore our curated courses spanning various subjects, designed specifically for O and A level students. Led by seasoned educators, our comprehensive curriculum ensures thorough understanding and effective exam preparation, empowering you to excel academically.
          </p>
        </div>
      </header>
      <StyledCardContainer>
        {BoardsTitles.map((item, index) => (
          <Card key={index}>
            <h2 className='text-main text-center'>{item.label}</h2>
            <p className='text-white text-lg text-center'>{item.paragraph}</p>
            <Link to="/contact" className='text-main text-center'>
              <button type='button' className='enroll-button'>Enroll</button>
            </Link>

          </Card>
        ))}
      </StyledCardContainer>
    </div>
  );
}

const StyledCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center align items horizontally */

  /* Add margin to the right of each card except the last one in each row */
  & > div:not(:last-child) {
    margin:10px
  }
`;

const Card = styled.div`
  width: calc(50% - 20px); /* Two cards per row initially */
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #09121b;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  text-align: center; /* Center text horizontally */

  /* Add styles to the button */
  .enroll-button {
    margin-top: auto; /* Push the button to the bottom */
    background-color: #FF7518;
    border: none;
    color: white;
    padding: 10px 50px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .enroll-button:hover {
    background-color: #FF7518; /* Change color on hover */
  }

  @media (max-width: 767px) {
    width: calc(100% - 20px); /* One card per row on smaller screens */
  }
`;

export default Boards;
