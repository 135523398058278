import React from 'react';
import { Link } from 'react-router-dom';
import ChooseSection from '../../components/ChooseSection/ChooseSection';
import StartCoursesImg from '../../utils/images/start-courses-img.jpg';
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';
import "./Home.css"
import Reviews from '../../components/Testimonails/Testimonials';
import { IMAGES } from "../../utils/strings/values"
import { ImageSlider } from '../../components/ImageSlider/ImageSlider';
import ContactUs from "../Contact/Contact"
import Courses from "../Courses/Courses"
import { FloatingWhatsApp } from "react-floating-whatsapp";

function Home() {
    return (
        <div className='home-page '>
            <header className='h-100 min-vh-100 flex items-center justify-center text-white bg-gray-900'>
                <div className='container text-center'>
                    <h2 className="text-3xl">Welcome To</h2>
                    <h1 className=' font-semibold header-title text-main'>Eahanatuitions</h1>
                    <p className=' mt-4 header-description  '> EAHANA provides personalized attention to each student, ensuring that they receive the guidance and support they need to succeed. Teachers work closely with students to understand their learning style, strengths, and weaknesses, and tailor their approach accordingly.</p>
                    <div className='flex flex-col items-center justify-center sm:flex-row'>
                        <Link to="/courses">
                            <button type='button' className=' mx-3 btn btn-danger btn-lg my-2 sm:my-0  sm:mx-2' style={{ backgroundColor: '#FF7518' }}>Our Courses</button>
                        </Link>
                        <Link to="/contact">
                            <button type='button' className='btn btn-outline-light btn-lg my-2 sm:my-0 mx-0 sm:mx-2'>Contact Us</button>
                        </Link>
                    </div>
                </div>
            </header>

            <div className="py-5 bg-[#09121b]">
                <ChooseSection />
            </div>

            <div className='py-5 bg-gray-100'>
                <div className="container">
                    <div className='flex flex-col-reverse items-center justify-center lg:flex-row lg:items-center'>
                        <div className='lg:w-1/2'>
                            <h2 className='text-3xl font-semibold mb-3'>2024 start courses</h2>
                            <p>Embark on your academic journey in 2024 with Eahan Tuitions' expert-led courses for O and A level students. Enroll now to unlock a world of knowledge and achieve your academic goals!</p>
                            <Link to="/courses">
                                <button type='button' className='btn btn-danger btn-lg my-3 sm:my-0' style={{ backgroundColor: '#FF7518' }}>Learn More</button>
                            </Link>
                        </div>
                        <div className='lg:w-1/2 mt-5 lg:mt-0'>
                            <img src={StartCoursesImg} className='w-full rounded-lg' alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='py-5 container '>
                <h1 className=' font-semibold header-title text-center pt-4'>Courses</h1>

                <Courses hideSomeElement={true} />
            </div>


            <div className='py-5 container text-center'>
                <h1 className=' font-semibold header-title pb-3'>Our Teachers</h1>

                <ImageSlider images={IMAGES} />

            </div>
            <div className='py-5  container text-center'>
                <h1 className=' font-semibold header-title'>Testimonials</h1>

                <Reviews />

            </div>

            <div className="py-5 ">
                <FaqAccordion />
            </div>

            <div className='py-3 container '>
                <h1 className=' font-semibold header-title text-center'>Contact Us</h1>

                <ContactUs hideSomeElement={true} />
            </div>



            <FloatingWhatsApp
                avatar={require("../../utils/images/teachers/teacher-1.jpg")}
                phoneNumber={"+923149312014"}
                accountName={"Eahanatuitions"}
            />



        </div>
    )
}

export default Home;
