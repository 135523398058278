import React from 'react';
import './Grades.css';
import { gradesTitle } from '../../utils/strings/values';
// import GradeCard from "../../components/GradeCard"
import styled from 'styled-components';
import { Link } from 'react-router-dom';




function Grades() {
    return (
        <div className='grades-page'>
            <header className='h-100vh'>
                <div className='header-content container vh-100 d-flex flex-column align-items-center justify-content-center text-light'>
                    <h1 className='header-title text-center fw-semibold text-main'>Grades</h1>
                    <p className='header-description'>
                        Starting with Key Stage 1 (KS1), young learners aged 5 to 7 dive into essential subjects like English, math, and science. As they progress to Key Stage 2 (KS2), spanning ages 7 to 11, the curriculum widens to include subjects such as history, geography, and more. Key Stage 3 (KS3), for ages 11 to 14, builds on this foundation, preparing students for secondary education with a broader understanding of various subjects.
                    </p>
                </div>
            </header>


            <StyledCardContainer>
                {gradesTitle.map((item, index) => (
                    <Card key={index}>  <h2 className='text-main text-center '>{item.label}</h2>

                        <p className='text-white text-lg text-center'>{item.paragraph}
                        </p>
                        <Link to="/contact" className='text-main text-center'>
                            <button type='button' className='enroll-button'>Enroll</button>
                        </Link>
                    </Card>

                ))}
            </StyledCardContainer>
        </div>
    )
}


const StyledCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Card = styled.div`
  width: calc(50% - 20px); /* Two cards per row initially */
  
  margin :10px;
  background-color: #09121b;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  text-align: center; /* Center text horizontally */

  /* Add styles to the button */
  .enroll-button {
    margin-top: auto; /* Push the button to the bottom */
    background-color: #FF7518;
    border: none;
    color: white;
    padding: 10px 50px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .enroll-button:hover {
    background-color: #FF7518; /* Change color on hover */
  }

  @media (max-width: 767px) {
    width: calc(100% - 20px); /* One card per row on smaller screens */
  }
`;


export default Grades;