import React from 'react';
import './About.css';
import ChooseSection from '../../components/ChooseSection/ChooseSection';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


function About() {
    return (
        <div className='about-page bg-[#09124b]'>
            <header className='min-vh-100 flex items-center justify-center text-white bg-gray-900'>
                <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                    <h1 className='text-center fw-semibold text-main'>About Us</h1>
                    <p className='text-center w-75 mb-5 header-description'>
                        EAHANA provides empowering, encouraging support to help students complete their assignments, improve their grades, and persist in their studies. Our professional and experienced teachers will discuss what you need from your course and design a lesson pathway to enable you to achieve your goals. Because each course is designed exclusively for you, lessons can be organised to fit in with your schedule.
                    </p>
                </div>
            </header>



            <div className='bg-[#09124b] py-5'>
                <ChooseSection />
            </div>

            <div className='flex justify-center text-white '>

                <Link to="/contact" className=' text-2xl text-center bg-main text-white px-8 py-2 rounded-lg mb-12'>
                    <button type='button' className='enroll-button'>Contact Us</button>
                </Link>
            </div>

        </div>
    )
}


export default About;