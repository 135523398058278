import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

function Contact({ hideSomeElement }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [message, setMessage] = useState('');
    const form = useRef();

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();


        if (!firstName || !lastName || !address || !email || !message) {
            alert("Please enter all entries");
            return;
        }

        // Send email using emailJS
        emailjs
            .sendForm('service_mm4cddq', 'template_4vyuloe', form.current, {
                publicKey: 'nx4NxKaW4sQS-4Jr5',

            })
            .then((result) => {
                console.log(result.text);
                // Reset form fields
                setFirstName('');
                setLastName('');
                setEmail('');
                setAddress('');
                setMessage('');
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div className='contact-page'>
            {!hideSomeElement && (<header className='min-vh-100 flex items-center justify-center text-white bg-gray-900'>
                <div className='header-content container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                    <h1 className='text-center text-main fw-semibold'>Get In Touch</h1>
                    <p className='text-center w-75 mb-5 header-description'>Elevate your learning journey with personalized tuition services tailored to your needs. Our experienced educators are here to guide you towards success. Reach out to us today and embark on your path to academic excellence.</p>
                </div>
            </header>)}

            <div className='container py-5 d-flex justify-content-center'>
                <Form id='contact-form' ref={form} onSubmit={handleSubmit}>
                    <Row className='mb-3'>
                        <Col sm={12} md={6} className='mb-3 mb-md-0'>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control placeholder='First name' id="first_name" name="firstName" value={firstName} onChange={handleFirstNameChange} />
                        </Col>
                        <Col sm={12} md={6}>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control placeholder='Last name' name="lastName" value={lastName} onChange={handleLastNameChange} />
                        </Col>
                    </Row>

                    <Form.Group className='mb-3'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type='email' placeholder='Enter email' name="email" value={email} onChange={handleEmailChange} />
                        
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label>Address</Form.Label>
                        <Form.Control placeholder='Your address' name="address" value={address} onChange={handleAddressChange} />
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label>Your Message</Form.Label>
                        <Form.Control as="textarea" rows={3} name="message" value={message} onChange={handleMessageChange} />
                    </Form.Group>

                    <Button variant="danger btn-lg" style={{backgroundColor:"#FF7518"}} type='submit'>Submit</Button>
                </Form>
            </div>
        </div>
    );
}

export default Contact;
