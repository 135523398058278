import React from 'react';

function TeacherCard({ name, education, picture }) {
    return (
        <div>
            <div className=" grid grid-cols-12 md:items-center rounded-md w-full max-w-screen-sm md:max-w-screen-md mx-auto px-4 my-3 ">
                <div className="col-span-12 md:col-span-auto md:col-start-1 md:col-end-9 md:row-start-1 md:row-end-1 bg-red-500">

                    <picture className="relative block w-full h-0 pb bg-gray-300 overflow-hidden shadow-lg" style={{ paddingTop: '75%' }}>
                        <img className="absolute inset-0 w-full h-full object-cover" src={picture} alt="teacher" />
                    </picture>

                </div>
                <div className="col-span-12 md:col-span-auto md:col-start-7 md:col-end-13 md:row-start-1 md:row-end-1 -mt-8 md:mt-0 relative z-10 px-4 md:px-0">
                    <div className="p-4 md:p-8 bg-[#09121b]">
                        <p className="mb-2 text-lg leading-none font-medium">
                            <h4 className='text-[#FF7518]'>
                                {name}
                            </h4>
                        </p>
                        <p className="mb-2 text-sm text-white">
                            {education}
                        </p>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeacherCard;
