import BiologyImg from "../../utils/images/biology.jpg";
import ChemistryCourseImg from "../../utils/images/chemistry.jpg";
import ComputerScienceCourseImg from "../../utils/images/computer-science-course.jpg";
import MathCourseImg from "../../utils/images/Maths.jpg";
import MathNumeracyCourseImg from "../../utils/images/Numeracy.jpg";
import EnglishLiteratureCourseImg from "../../utils/images/English literature.jpg";
import PhysicsCourseImg from "../../utils/images/physics.jpg";
import ReligiousCourseImg from "../../utils/images/religious.jpg";
import GeographyCourseImg from "../../utils/images/geography.jpg";
import HistoryCourseImg from "../../utils/images/history.jpg";
import PyscologyCourseImg from "../../utils/images/pyscology.jpg";
import SociologyCourseImg from "../../utils/images/Maths.jpg";

export const reviews = [
  {
    id: 1,
    name: "susan smith",
    job: "web developer",
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883334/person-1_rfzshl.jpg",
    text: "I'm baby meggings twee health goth +1. Bicycle rights tumeric chartreuse before they sold out chambray pop-up. Shaman humblebrag pickled coloring book salvia hoodie, cold-pressed four dollar toast everyday carry",
  },
  {
    id: 2,
    name: "anna johnson",
    job: "web designer",
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883409/person-2_np9x5l.jpg",
    text: "Helvetica artisan kinfolk thundercats lumbersexual blue bottle. Disrupt glossier gastropub deep v vice franzen hell of brooklyn twee enamel pin fashion axe.photo booth jean shorts artisan narwhal.",
  },
  {
    id: 3,
    name: "peter jones",
    job: "intern",
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883417/person-3_ipa0mj.jpg",
    text: "Sriracha literally flexitarian irony, vape marfa unicorn. Glossier tattooed 8-bit, fixie waistcoat offal activated charcoal slow-carb marfa hell of pabst raclette post-ironic jianbing swag.",
  },
  {
    id: 4,
    name: "bill anderson",
    job: "the boss",
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883423/person-4_t9nxjt.jpg",
    text: "Edison bulb put a bird on it humblebrag, marfa pok pok heirloom fashion axe cray stumptown venmo actually seitan. VHS farm-to-table schlitz, edison bulb pop-up 3 wolf moon tote bag street art shabby chic. ",
  },
];

export const gradesTitle = [
  {
    label: "KS1",
    //   image: require("../images/grades/1.png"),
    paragraph:
      "KS1 (Key Stage 1) refers to the first two years of primary education in England and Wales, typically covering ages 5 to 7. During this grade, children focus on foundational skills in subjects like English, mathematics, and science, with an emphasis on developing basic literacy and numeracy abilities.",
  },
  {
    label: "KS2",
    //   image: require("../images/grades/2.png"),
    paragraph:
      "KS2 stands for Key Stage 2 and is an educational level for students in the UK aged 7-11 years old. It covers the last four years of primary school education and includes subjects like English, maths, science, history, geography, and more.",
  },
  {
    label: "KS3",
    //   image: require("../images/grades/3.png"),
    paragraph:
      "KS3 stands for Key Stage 3 and is an educational level for students in the UK aged 11-14 years old. It covers the first three years of secondary school education and includes subjects like English, maths, science, history, geography, and more.",
  },
  {
    label: "GCSE",
    //   image: require("../images/grades/4.png"),
    paragraph:
      "GCSE (General Certificate of Secondary Education) is an educational level for students in the UK aged 14-16 years old. It is a two-year program and covers a wide range of subjects, including English, maths, science, languages, and humanities. At the end of the program, students sit for exams and receive a qualification that is recognized by universities and employers.",
  },
  {
    label: "IGCSE",
    //   image: require("../images/grades/5.png"),
    paragraph:
      "IGCSE (International General Certificate of Secondary Education) is an international version of the GCSE and is recognized by universities and employers around the world. It is also a two-year program, covers similar subjects to GCSE, and is designed to prepare students for further study or employment.",
  },
  {
    label: "AS",
    //   image: require("../images/grades/6.png"),
    paragraph:
      "AS (Advanced Subsidiary) is the first year of the A-level program in the UK. It is an educational level for students aged 16-17 years old and covers subjects in greater depth than GCSE. At the end of the AS level, students can either choose to continue to A2 level or end their studies.",
  },
  {
    label: "A-level",
    //   image: require("../images/grades/7.png"),
    paragraph:
      "A-level is an educational level for students in the UK aged 16-18 years old and is a two-year program. It is a higher level of education than AS level and covers subjects in greater depth. A-level qualifications are recognized by universities and employers and are essential for many degree courses.",
  },
  {
    label: "Summary",
    //   image: require("../images/grades/1.png"),
    paragraph:
      "In summary, KS2 and KS3 are levels of primary and secondary education, respectively, while GCSE, IGCSE, AS, and A-level are levels of secondary and post-secondary education that provide students with qualifications recognized by universities and employers.",
  },
];

export const courses = [
  {
    id: 1,
    img: [BiologyImg],
    title: "Biology-Single-Science",
    description:
      " Bonding, structure and the properties of matter,  Quantitative chemistry,    Chemical changes,   States of matter and mixtures,    Groups in Periodic Table,   Particles,    Elements, compounds and mixtures,   Chemical reactions",
  },
  {
    id: 2,
    img: [ChemistryCourseImg],
    title: "chemistry-single-science",
    description:
      "Bonding, structure and the properties of matter,       Quantitative chemistry,       Chemical changes,        States of matter and mixtures,        Groups in Periodic Table,        Particles,        Elements, compounds and mixtures,        Chemical reactions",
  },
  {
    id: 3,
    img: [ComputerScienceCourseImg],
    title: " Computer Science",
    description:
      "Computational thinking and problem solving,        Theoretical knowledge,        Study skills,        Fundamentals of algorithms,        Computer systems,        Computational thinking, algorithms and programming,",
  },
  {
    id: 4,
    img: [MathCourseImg],
    title: "Maths",
    description:
      "Number,        Algebra,        Ratio, proportion and rates of change,        Rati",
  },
  {
    id: 5,
    img: [MathNumeracyCourseImg],
    title: "Maths Numeracy (WJEC)",
    description: "Analysing fiction, Comparing texts, Analysing non-fiction",
  },
  {
    id: 6,
    img: [EnglishLiteratureCourseImg],
    title: "English Literature",
    description: "Shakespeare, Post 1914 Prose/Drama, 19th-century prose",
  },
  {
    id: 7,
    img: [PhysicsCourseImg],
    title: "Physics (Single Science)",
    description:
      "Energy,        Electricity,        Particle model of matter,        Motion and forces,        Conservation of energy,        Matter,",
  },
  {
    id: 8,
    img: [ReligiousCourseImg],
    title: "Religious Studies (QURAN AND TAFSEER)",
    description: "Quran, Tafseer",
  },
  {
    id: 9,
    img: [GeographyCourseImg],
    title: "Geography",
    description:
      "Living with the physical environment,        Challenges in the human environment,        Geographical skills",
  },
  {
    id: 10,
    img: [HistoryCourseImg],
    title: "History",
    description:
      "Early civilisations and empires (pre 12th century),        Tudors and Stuarts (15th to 17th century),        Modern World history (20th century),  Migration to Britain c1000 to c2010",
  },
  {
    id: 11,
    img: [SociologyCourseImg],
    title: "SOCIOLOGY (GCSE ALEVELS O LEVELS)",
    description:
      "The sociological approach,   Social structures, social processes and social issues,        Families,        Education,        Crime and deviance,    Social stratification,        Sociological research methods,",
  },
  {
    id: 12,
    img: [PyscologyCourseImg],
    title: "PSYCOLOGY (GCSE ALEVELS O LEVELS)",
    description: "Cognition and behaviour, Social context and behaviour",
  },
];

export const BoardsTitles = [
  {
    // image: require("../images/boards/1.png"),
    label: "AQA",
    paragraph:
      "AQA (Assessment and Qualifications Alliance) is a leading provider of academic qualifications in the UK. They offer a wide range of GCSE and A-level subjects, as well as vocational qualifications and exams for students with special needs. EAHANA offers online tuition for all subjects of AQA Exam Board.",
  },
  {
    // image: require("../images/boards/2.png"),
    label: "CCEA",
    paragraph:
      "CCEA (Council for the Curriculum, Examinations and Assessment) is the Northern Irish examination board. They offer a range of GCSE and A-level subjects, as well as vocational qualifications and exams in Irish language and culture. EAHANA offers online tuition for all subjects of CCEA Exam Board.",
  },
  {
    // image: require("../images/boards/3.png"),
    label: "Edexcel",
    paragraph:
      "Edexcel is a subsidiary of Pearson, a global education company. They offer a wide range of GCSE and A-level subjects, as well as vocational qualifications and exams in business and technology. EAHANA offers online tuition for all subjects of Edexcel Exam Board.",
  },
  {
    // image: require("../images/boards/4.png"),
    label: "Eduqas",
    paragraph:
      "Eduqas is the Welsh examination board. They offer a range of GCSE and A-level subjects, as well as vocational qualifications and exams in Welsh language and culture. EAHANA offers online tuition for all subjects of Eduqas Exam Board.",
  },
  {
    // image: require("../images/boards/5.png"),
    label: "OCR 21st Century",
    paragraph:
      "OCR 21st Century: OCR (Oxford, Cambridge and RSA Examinations) is a leading provider of academic qualifications in the UK. OCR 21st Century is their science curriculum, which includes GCSE and A-level qualifications in biology, chemistry, physics, and combined science. EAHANA offers online tuition for all subjects of OCR 21st Century Exam Board.",
  },
  {
    // image: require("../images/boards/6.png"),
    label: "OCR Gateway",
    paragraph:
      "OCR Gateway is another science curriculum offered by OCR, which includes GCSE and A-level qualifications in biology, chemistry, physics, and combined science. It is designed to prepare students for further study and careers in science-related fields. EAHANA offers online tuition for all subjects of OCR Gateway Exam Board.",
  },
  {
    // image: require("../images/boards/7.png"),
    label: "WJEC",
    paragraph:
      "WJEC (Welsh Joint Education Committee) is the largest examination board in Wales. They offer a range of GCSE and A-level subjects, as well as vocational qualifications and exams in Welsh language and culture. They also offer a range of international qualifications and assessments. EAHANA offers online tuition for all subjects of WJEC Exam Board.",
  },
];

export const teachersData = [
  {
    name: "John Doe",
    education: "Bachelor of Science in Mathematics",
    picture: require("../images/teachers/teacher-1.jpg"),
  },
  {
    name: "Jane Smith",
    education: "Master of Arts in English Literature",
    picture: require("../images/teachers/teacher-2.jpg"),
  },
  {
    name: "Michael Johnson",
    education: "Ph.D. in Physics",
    picture: require("../images/teachers/teacher-3.jpg"),
  },
  {
    name: "Emily Brown",
    education: "Bachelor of Education in History",
    picture: require("../images/teachers/teacher-4.jpg"),
  },
  {
    name: "Daniel Martinez",
    education: "Bachelor of Science in Chemistry",
    picture: require("../images/teachers/teacher-5.jpg"),
  },
  {
    name: "Jessica Taylor",
    education: "Master of Science in Biology",
    picture: require("../images/teachers/teacher-6.jpg"),
  },
  {
    name: "David Anderson",
    education: "Bachelor of Arts in Psychology",
    picture: require("../images/teachers/teacher-7.jpg"),
  },
  {
    name: "Laura Wilson",
    education: "Master of Education in Special Education",
    picture: require("../images/teachers/teacher-8.jpg"),
  },
];

export const IMAGES = [
  {
    url: require("../images/ImageSlider/Imageslider-1.png"),
    alt: "Person One",
  },
  {
    url: require("../images/ImageSlider/Imageslider-2.png"),
    alt: "Person Two",
  },
  {
    url: require("../images/ImageSlider/Imageslider-3.png"),
    alt: "Person Three",
  },
  {
    url: require("../images/ImageSlider/Imageslider-4.png"),
    alt: "Person Four",
  },
  {
    url: require("../images/ImageSlider/Imageslider-5.png"),
    alt: "Person Five",
  },
  {
    url: require("../images/ImageSlider/Imageslider-6.png"),
    alt: "Person Six",
  },
  
];
