import React from 'react';
import './Courses.css';
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';
import { useNavigate } from 'react-router-dom';
import { courses } from '../../utils/strings/values';

function Courses({ hideSomeElement }) {
    const navigate = useNavigate();

    return (
        <div className='courses-page '>
            {!hideSomeElement && (<header className='header'>
                <div className='header-content container vh-100 d-flex flex-column align-items-center justify-content-center text-light'>
                    <h1 className='header-title text-center fw-semibold text-main'>Our Courses</h1>
                    <p className='header-description'>
                        Explore our curated courses spanning various subjects, designed specifically for O and A level students. Led by seasoned educators, our comprehensive curriculum ensures thorough understanding and effective exam preparation, empowering you to excel academically.
                    </p>
                </div>
            </header>)}

            <div className='container py-5 '>
                <div className='row g-4'>
                    {courses.map((course) => (
                        <div key={course.id} className='col-lg-6'>
                            <div className='custom-card'>
                                <img className='custom-card-img' src={course.img} alt={course.title} />
                                <div className='custom-card-overlay'>
                                    <h2 className='custom-card-title'>{course.title}</h2>
                                    <p className='custom-card-description'>{course.description}</p>
                                    <button onClick={() => navigate("/contact")} type='button' className='custom-card-button'>Enroll</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {!hideSomeElement && (<div className='text-black py-5'>
                <FaqAccordion />
            </div>)
            }
        </div>
    );
}

export default Courses;
