import React from 'react';
import './Teachers.css';
import TeacherCard from '../../components/TeacherCard';
import { teachersData } from "../../utils/strings/values"

function Teachers() {


  return (
    <div className='teachers-page '>
      <header className='header  mb-12'>
        <div className='header-content container vh-100 d-flex flex-column align-items-center justify-content-center text-light'>
          <h1 className='header-title text-center fw-semibold text-main'>Our Teachers</h1>
          <p className='header-description'>

            Welcome to Our Teachers Page at Eahanatuitions! Meet our dedicated team of educators who are committed to guiding O and A level students towards academic excellence. With years of experience and a passion for teaching, our teachers employ innovative methods to make learning engaging and effective. Get ready to embark on a transformative educational journey with our talented instructors who are here to support and inspire you every step of the way..
          </p>
        </div>
      </header>

      <div className='flex flex-wrap justify-center'>
        {teachersData.map((teacher, index) => (
          <div key={index} className='w-full mx-2 py-8 md:w-1/3'>
            <TeacherCard name={teacher.name} education={teacher.education} picture={teacher.picture} />
          </div>
        ))}
      </div>
    </div>

  );
}

export default Teachers;
