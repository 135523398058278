import React from "react";
import {
  FaFacebook,
  FaInstagramSquare,
  FaLinkedin,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import Logo from "../utils/images/eahana.png";
import { IoLogoWhatsapp } from "react-icons/io";

const CustomButton = (props) => {
  return (
    <div
      onClick={props.handler}
      rel="noopener noreferrer"
      className="animatingContactUsOfBottom transition-all duration-500 flex justify-center items-center w-32 h-8 rounded-sm  text-white hover:text-primary mt-2 cursor-pointer"
    >
      <h1 className="text-sm">{props.label}</h1>
    </div>
  );
};

function Footer() {
  const handleWhatsAppClick = () => {
    const phoneNumber = "00923149312014";
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };
  return (
    <footer className="bg-[#09121b] py-6 relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mb-8">
          <div className="flex items-center justify-center sm:col-span-2 md:col-span-1">
            <img src={Logo} alt="logo" width={500} />
          </div>
          <div className="flex flex-col text-white items-center justify-center">
            <p className="mt-6 text-xl font-medium">Contact Info</p>
            <p className="text-md mt-1">eahanatuitions@gmail.com</p>
            <p className="text-md">+92-3149312014</p>
          </div>
          <div className="flex flex-col  text-white items-center justify-center">
            <p className="text-md mt-6 font-medium">
              Chowk, Asghar mall, Office D3
            </p>
            <p className="text-md mt-1"> Shah Plaza, Murree Rd</p>
            <p className="text-md"> Rawalpindi</p>
          </div>
          <div className="text-white flex flex-col justify-center items-center">
            <p className="text-lg font-medium">Follow Us</p>
            <div className="flex mt-1">
              <a
                href="https://www.facebook.com/ABBAS1512/"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2 text-xs hover:bg-[#ffffff] "
              >
                <FaFacebook size={24} color="#7393B3" />
              </a>
              <a
                href="https://instagram.com/eahana12?igshid=NTc4MTIwNjQ2YQ=="
                target="_blank"
                rel="noopener noreferrer"
                className=" mx-2 text-xs mr-2 hover:bg-[#ffffff] "
              >
                <FaInstagramSquare size={24} color="	#7393B3" />
              </a>
              <a
                href="https://www.linkedin.com/in/eahana-the-premier-tutors-online-academy-12771b275"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2 text-xs mr-2 hover:bg-[#ffffff]"
              >
                <FaLinkedin size={24} color="	#7393B3" />
              </a>
              <a
                href="https://twitter.com/eahanatuitions?t=8B2GIsD-DBon6PXRitw2iA&s=08"
                target="_blank"
                rel="noopener noreferrer"
                className="text-xs mx-2 hover:bg-[#ffffff]"
              >
                <FaTwitter size={24} color="	#7393B3" />
              </a>
              <a
                href="https://wa.me/00923149312014"
                target="_blank"
                rel="noopener noreferrer"
                className="text-xs mx-2 hover:bg-[#ffffff]"
              >
                <FaWhatsapp size={24} color="	#7393B3" />
              </a>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 text-center text-gray-500 text-sm">
          <p>
            &copy; {new Date().getFullYear()} Your Company Name. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
